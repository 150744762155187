@import "../variables-etc.scss";

.stepview {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 2em -3em 0;

  > * {
    display: flex;
    flex-direction: column;

    > *:nth-child(1) {
      flex-grow: 1;
    }
  }

  &_block {
    width: rem(260 * 0.66);
    // height: rem(260 * 0.66);
    padding-bottom: 20px;
    box-shadow: 0 rem(3 * 0.66) rem(10 * 0.66) rgba(0, 0, 0, 0.16);
    border: rem(1 * 0.66) solid transparent;
    margin: rem(10 * 0.66);
    cursor: pointer;
    &:hover,
    &_active {
      border: rem(1 * 2.4) solid $pink;
      box-shadow: 0 rem(12 * 0.66) rem(12 * 0.66) rgba(0, 0, 0, 0.16);
    }
  }
  &_bg {
    display: block;
    width: rem(236 * 0.66);
    height: rem(180 * 0.66);
    margin: rem(10 * 0.66) auto rem(20 * 0.66);
    background-size: cover;
  }
  &_ico {
    margin: 1.5em auto;
  }
  &_label {
    background: transparent;
    width: 100%;
    text-align: center;
    border: 0;
    color: #2c2828;
    font-size: rem(20 * 0.66);
    font-weight: 600;
  }
}

.modal_title {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.modal_button {
  width: 200px;
  margin: 5% auto;
}
