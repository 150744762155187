@import '../variables-etc.scss';

.masking_spinner_container {
  position: relative;
  z-index: 1;
}

.masking_spinner_holder {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #ffffffa9;
  display: flex;
  align-items: center;
  justify-content: center;
}
