@import "../variables-etc.scss";

.es-step-view {
  display: flex;
  flex-direction: column;

  input[type="range"] {
    width: 75%;
    margin: auto auto 2%;
  }

  .title_container {
    margin: 5% auto;
    display: flex;
  }
}

.labels_container {
  display: flex;

  div {
    text-align: center;
    width: 50%;
    font-weight: 600;
    position: relative;

    span {
      font-weight: normal;
    }

    &:nth-child(1):not(.support-state_information),
    &:nth-child(3):not(.support-state_information--old) {
      width: 25%;
    }
  }
}

.support-state_information.support-state_information {
  position: absolute;
  width: 55%;
  text-align: left;
  left: 56%;
  transform: translate(-60%, 0);
  top: 150%;
  border: 1px solid $pink;
  padding: 3% 4%;
  margin-bottom: 5%;
}

.support-state_information--new.support-state_information--new,
.support-state_information--old.support-state_information--old {
  width: 100%;
  left: 60%;
}

.not-displayed {
  display: none;
}

@include lessThan(sm) {
  .es-step-view {
    margin: auto -3em;
  }

  .labels_container {
    width: 95%;
    margin: auto;
  }

  .support-state_information--new.support-state_information--new,
  .support-state_information--old.support-state_information--old {
    width: 300%;
  }

  .support-state_information--good.support-state_information--good {
    width: 150%;
    left: 65%;
  }

  .support-state_information--new.support-state_information--new {
    margin: auto auto auto 175%;
  }

  .support-state_information--old.support-state_information--old {
    margin: auto auto auto -135%;
  }
}
