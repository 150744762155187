@import "../variables-etc.scss";

.ui-tp-grid {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
}
.ui-ns {
  grid-column: span 8;
}
.ui-tp-filtre.ui-tp-filtre {
  flex-grow: 0;
  border: none;
}

.ma-step--card-body.ma-step--card-body {
  padding: 0;
  margin: auto;
}

@media (max-width: 1200px) {
  .ma-step--card-body.ma-step--card-body {
    display: flex;
    flex-direction: column;

    div:first-child {
      // margin-right: 5%;
    }
  }

  .content_container.content_container {
    display: flex;
    flex-direction: column-reverse;
  }
}

.content_container {
  // display: grid;
  // grid-template-columns: repeat(10, 1fr);
  display: flex;
  margin-top: 4%;

  > *:first-child {
    flex-grow: 1;
  }

  // @include lessThan(md) {
  //   display: flex;
  //   flex-direction: column-reverse;
  // }
}

.brand-filter {
  display: flex;
  // width: fit-content;

  label {
    display: flex;
    width: 100%;
    cursor: pointer;
    height: 100%;
    padding: 5px 5px 5px 25px;
    margin-bottom: 10px;
  }

  input:checked + label {
    border: 0.15rem solid $pink;
  }

  span {
    margin: auto 10% auto auto;
  }
}

.stepview_bg--ma-step-view.stepview_bg--ma-step-view {
  height: 40px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.stepview_block-matiere.stepview_block-matiere {
  padding-bottom: 0;

  label {
    cursor: pointer;
    height: 100%;
    margin-bottom: 0;
    padding-bottom: 20px;
  }
}

.custom-control-label--matiere.custom-control-label--matiere {
  &:before,
  &:after {
    left: 0.9rem;
    top: 50%;
    transform: translate(0, -50%);
  }
}
