@import "../variables-etc.scss";

.projects_list {
  list-style-type: none;
  &:empty {
    display: none;
  }

  li {
    margin-bottom: 10px;
    background-color: $gray;
    border: none;

    &:hover {
      box-shadow: 0px 3px 5px grey;
    }
  }
}

.project-legend {
  margin: 0 10px;

  span {
    margin-left: 5px;
  }
}

@include lessThan(lg) {
  .project-legend {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    span {
      margin-left: 0;
    }
  }
}
