@import "../variables-etc.scss";

.projectmesure {
  &_icoquestion {
    display: inline-block !important;
    margin-left: 1em;
  }
  &_pic_mesure {
    width: 50px;
    margin-right: 1.5em;
  }
  &_label {
    font-weight: 500;
  }

  &_zone {
    margin: 2em 0;

    input {
      width: 40px;
      // height: fit-content;
      text-align: center;
      margin: 0 1em;
    }
    input[type="button"] {
      border-radius: 50px;
      width: 30px;
      color: white;
      background: #e6786b;
      border: none;
      height: 29px;
      line-height: 29px;
      font-size: 1.5em;
      margin: 0;
    }
    &:first-child {
      border-right: 1px dashed #c0b3a9;
    }
    &_input {
      margin-bottom: 2em;
    }
    .trash {
      cursor: pointer;
    }
  }
}

@include lessThan(xl) {
  .projectmesure {
    &_zone {
      &:first-child {
        border-bottom: 1px dashed #c0b3a9;
        padding-bottom: 45px;
        border-right: 0;
      }
    }
  }
}

@include lessThan(md) {
  .zone {
    div {
      padding: 0;
    }
  }
}

.zone {
  border-top: 1px solid #c0b3a9;
  padding: 1em 0;

  svg {
    width: 20px;
  }
  input[type="number"] {
    width: 50%;
    height: 30px;
    padding-right: 8px;
    box-sizing: border-box;
    text-align: end;
    margin-right: 0;
  }
  label {
    margin: auto 0 auto 5px;
    white-space: nowrap;
    display: inline-block;
  }
}

.project-mesure {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: rem(10 * 0.66);

  > * {
    color: $blue;
  }
}

.mesure-header {
  grid-column: 1/7;
  grid-row: 1;
  text-align: center;
}

.mesure-total {
  grid-column: 2/6;
  // grid-row: 2;
  // display: grid;
  // grid-template-columns: repeat(8, 1fr);
  // grid-gap: rem(10 * 0.66);
  display: flex;
  margin: auto;

  padding: 1em;
}

.mesure-unit {
  grid-column: 1/3;
  grid-row: 3;
}

.number-zones-couve-ret {
  grid-column: 1/7;
  grid-row: 4;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: rem(10 * 0.66);

  > div:nth-child(3) {
    position: relative;

    &:after {
      content: "";
      position: absolute;
    }
  }
}

.label-zones-couve-ret {
  grid-column: 1/7;
  grid-row: 5;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: rem(10 * 0.66);

  &::after {
    content: "";
    position: absolute;
    border-right: 1px dashed black;
    height: 21%;
    top: 50%;
    left: 57%;
    background: white;
    width: 25px;
  }

  > div {
    display: flex;

    > label {
      margin: auto 0;
    }
  }
}

.calcul-zones-couve-ret {
  grid-column: 1/7;
  grid-row: 6;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: rem(10 * 0.66);
  grid-auto-flow: dense;
  border-top: 1px solid $blue;
  padding-top: 20px;
  // height: fit-content;
}

.number-zones-couve-ret div:nth-child(1) {
  grid-column: 1/4;
  grid-row: 1;
}
.number-zones-couve-ret div:nth-child(2) {
  grid-column: 4/5;
  grid-row: 1;
}
.number-zones-couve-ret div:nth-child(3) {
  grid-column: 6/7;
  grid-row: 1;
  // border-right: rem(1 * 0.66) solid black;
}

.number-zones-couve-ret div:nth-child(4) {
  grid-column: 7/10;
  grid-row: 1;
  margin-left: 20px;
}
.number-zones-couve-ret div:nth-child(5) {
  grid-column: 10/11;
  grid-row: 1;
}

.mesure-total {
  div:nth-child(1) {
    grid-column: 3;
    grid-row: 1;
    font-weight: 700;
  }

  div:nth-child(2) {
    grid-column: 4/6;
    grid-row: 1;
    text-align: center;
    display: flex;
    margin: auto 8px;

    label {
      margin: auto;
    }
  }
}

.surface-total {
  grid-column: 6;
  display: flex;
  margin: auto;
  background: #f7f5f5;
  padding: 0.5em 1em;
  border-radius: 5px;
}

.total {
  grid-column: 1;
  grid-row: 1;
  font-size: 2em;
  margin: auto;
}

.mesure {
  grid-column: 2;
  grid-row: 1;
  margin: auto {
    margin-left: 5px;
  }
}

.ui-unit-choice-mesure {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: rem(10 * 0.66);

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: $blue;
  }

  [type="radio"]:checked + label:m,
  [type="radio"]:not(:checked) + label:m {
    content: "";
    position: absolute;
    left: -6px;
    top: -5px;
    width: 27px;
    height: 27px;
    border: 1px solid $blue;
    border-radius: 100%;
    background: #fff;
  }

  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 17px;
    height: 17px;
    background: $blue;
    position: absolute;
    top: 0;
    left: -1px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: "";
    width: 25px;
    height: 25px;
    background: white;
    position: absolute;
    top: -4px;
    left: -5px;
    border: 1px solid $blue;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

input {
  /* Firefox */
  -moz-appearance: textfield;

  /* Chrome */
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Opéra*/
  &::-o-inner-spin-button {
    -o-appearance: none;
    margin: 0;
  }
}
