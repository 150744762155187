@import "../variables-etc.scss";

.ui-profile-edit-wrapper {
  div {
    form {
      display: flex;
      flex-direction: column;

      input {
        margin-bottom: 5%;
      }
    }
  }

  display: grid;
  grid-template-columns: 25% 60%;
  grid-row: auto auto;
  grid-column-gap: 15%;
  grid-row-gap: 10%;
}

.blue_label {
  font-size: 16px;
  font-weight: 600;
  color: $blue;
  display: block;
  margin-bottom: 5px;
}

.button_container {
  margin-top: 5%;
}

.back_link {
  svg {
    height: 20px;
    margin-right: 5px;
  }
}

.plus_button {
  background-color: $red;
  border-radius: 25px;
  color: white;
  border: none;
  height: 30px;
  width: 30px;
  position: relative;
  cursor: pointer;

  &:before {
    content: "+";
    position: absolute;
    top: -5px;
    left: 6px;
    font-size: 2em;
  }
}

.input-radio_container {
  display: flex;

}

.linecheckbox_container.linecheckbox_container {
  float: none;
}

// .delete-input_container {
//   input {
//     visibility: hidden;
//   }

//   label {
//     &:before {

//     }
//   }
// }

@media (max-width: 768px) {
  .ui-profile-edit-wrapper {
    grid-template-columns: 100%;
  }
}
