@import "../variables-etc.scss";

.modal90W {
  width: 90%;
  max-width: none !important;
}

.modal90W .modal-body {
  overflow: auto;
  height: 70vh;
}

.current {
  color: #e6786b !important;
}

.opened {
  text-decoration: underline !important;
}

.tree-button {
  margin-left: 20px !important;
  margin-bottom: 15px;
}

.tree-button > span {
  font-size: 0.9em;
}

.tree {
  list-style-type: none;
  // max-width: 100%;
  // overflow: auto;
}

.tree > li > ul {
  margin:0 0 0 1em; /* indentation */
  padding:0;
  list-style:none;
  position:relative;
}

.tree > li > ul {margin-left:.5em} /* (indentation/2) */

.tree > li > ul:before {
  content:"";
  display:block;
  width:0;
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  border-left:1px dotted;
}

.tree > li {
  margin:0;
  padding:0 1.5em; /* indentation + .5em */
  line-height:2em; /* default list item's `line-height` */
  font-weight:bold;
  position:relative;
}

.tree > li:before {
  content:"";
  display:block;
  width:10px; /* same with indentation */
  height:0;
  border-top:1px dotted;
  margin-top:-1px; /* border top width */
  position:absolute;
  top:1em; /* (line-height/2) */
  left:0;
}

.tree > li:last-child:before {
  background:white; /* same with body background */
  height:auto;
  top:1em; /* (line-height/2) */
  bottom:0;
}

@media (max-width: 400px) {
  .modal-content {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .modal90W {
    width: 100% !important;
    max-width: none !important;
    margin: 0 !important;
    padding: 0.5rem !important;
  }

  .modal90W .modal-body {
    height: 80vh;
  }

  .tree ul {
    width: 250px;
  }
}
