@import "../variables-etc.scss";

.breadcrumb ul {
  margin: 0;
  margin-bottom: rem(10 * 0.66);
  padding: 0;
  list-style-type: none;
  a {
    color: $black;
  }
  li {
    display: inline-block;

    &:not(:last-child)::after {
      content: " > ";
      display: inline-block;
      margin: 0 1em;
      color: $pink;
      font-weight: 600;
    }
  }
}
