@import "../variables-etc.scss";

.newproject {
  &_new {
    margin-top: rem(30 * 0.66);
  }
  &_label {
    font-size: rem(25 * 0.66);
    color: $blue;
    margin-right: rem(20 * 0.66);
  }
  &_input {
    display: inline-block !important;
    width: rem(350 * 0.66) !important;
    color: $blue !important;
    font-size: rem(30 * 0.66) !important;
    padding: 1em 0.5em !important;
  }
  &_title {
    text-align: center;
    margin-top: 2em;
  }
  &_icoquestion {
    display: inline-block !important;
    margin-left: 1em;
  }
}
