@import "../variables-etc.scss";

.ui-error {
  color: $errorColor;
  margin-top: 0 !important;
}

.ui-reset-password {
  height: 100%;

  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    vertical-align: text-top;
    height: 50%;
  }
}

.blue_label {
  font-size: 16px;
  font-weight: 600;
  color: $blue;
  display: block;
  margin-bottom: 5px;
}

.button_container {
  margin-top: 5%;
}
