@import "../variables-etc.scss";

.ui-tp-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto -3em;
}
.ui-tp {
  grid-column: span 8;
}
.ui-tp-filtre {
  grid-column: span 2;
}

.stepview_block-matiere.stepview_block-matiere {
  padding-bottom: 0;

  label {
    cursor: pointer;
    height: 100%;
    margin-bottom: 0;
    padding-bottom: 20px;
  }
}
