@import "../variables-etc.scss";

.projet-recap__title {
  margin-bottom: 30px;
}

.projet-recap__container {
  display: flex;
  flex-direction: column;
}

.projet-recap__substep-header {
  display: flex;

  .projet-recap__child-label {
    width: 80%;
    text-transform: uppercase;
    font-size: 1.5em;
    text-align: left;
  }

  span,
  label,
  div {
    margin: auto;
    text-align: center;
  }

  .projet-recap__substep-area {
    min-width: 80px;
    width: inherit;
  }

  .projet-recap__btn {
    display: flex;
  }

  .projet-recap__btn-modif input,
  .projet-recap__btn-suppr {
    color: white;
    border: none;
  }

  .projet-recap__btn-modif input {
    background-color: $cyan;
  }

  .projet-recap__btn-modif span {
    font-size: 12px;
  }

  .projet-recap__btn-modif button {
    height: 37px;
    padding: 6px 12px !important;
  }

  .projet-recap__btn-suppr {
    background-color: $red;
  }

  .projet-recap__btn-suppr span {
    font-size: 12px;
  }

  .projet-recap__btn-suppr {
    height: 37px;
    padding: 6px 12px !important;
  }
}

.projet-recap__grid-container {
  border: 1px solid #ddd;
  margin-bottom: 1em;
  padding: 2em 1em;
}

.projet-recap__grid {
  grid-gap: 2em;
  padding: 1em 0;

  &:not(:last-child) {
    border-bottom: rem(1 * 0.66) solid #ddd;
  }

  label {
    margin-bottom: 0;
  }

  label:last-child {
    text-align: center;
  }
}

.projet-recap__grid,
.projet-recap__grid-header {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr 1fr;
}

.projet-recap__grid-header {
  grid-gap: 2em;
  padding: 1em 0;
}

.projet-recap__grid-item-picture {
  width: 125px;
}

.projet-recap__grid-item-quantity {
  text-align: center;
}

.projet-recap__grid-item-link {
  text-align: end;
  a {
    color: $cyan;
    text-decoration: underline;
  }
}

@include lessThan(sm) {
  .projet-recap__substep-header {
    display: flex;

    .projet-recap__child-label {
      font-size: 1em;
    }
  }

  .projet-recap__btn {
    flex-direction: column;
  }

  .projet-recap__grid {
    flex-direction: column;

    * {
      margin: auto;
    }
  }
}

@include lessThan(lg) {
  .projet-recap__grid {
    display: flex;

    label,
    .projet-recap__grid-item-quantity {
      margin: 0 auto;
    }
  }
}

// .ui-recap-grid {
//   max-width: rem(1000 * 0.66);
//   margin: auto;
//   display: grid;
//   grid-template-columns: 1fr 4fr 1fr;
//   grid-gap: 2em;
//   color: #222;
//   border-bottom: rem(1 * 0.66) solid #ddd;
//   padding: 1em 0;
// }

// .ui-recap-grid label {
//   font-weight: 800;
// }
// .ui-recap-grid label:first-child {
//   text-align: right;
// }

// .ui-recap-grid-header {
//   text-decoration: underline;
//   border-bottom: unset;
// }

// @media (max-width: rem(600 * 0.66)) {
//   .ui-recap-grid {
//     grid-template-columns: 1fr;
//     grid-gap: 0.5em;
//   }

//   .ui-recap-grid label:first-child {
//     text-align: left;
//     margin: 0;
//   }

//   .ui-reference-col {
//     margin-top: -rem(10 * 0.66);
//     font-style: italic;
//     order: 1;
//   }

//   .ui-quantity-col {
//     order: 2;
//   }

//   .ui-recap-grid-header {
//     display: none;
//   }
// }

// body {
//   font-family: "Open Sans", sans-serif;
// }

// h2 {
//   text-align: center;
// }

// .ui-recap-wrapper {
//   padding: 0 1em;
// }

// .ui-recap {
//   display: grid;
//   grid-template-rows: repeat(auto-fill, minmax(rem(280 * 0.66), 1fr));
//   grid-gap: rem(20 * 0.66);
//   align-items: stretch;
// }

// .ui-recap > div {
//   border: rem(1 * 0.66) solid #ccc;
//   box-shadow: rem(2 * 0.66) rem(2 * 0.66) rem(6 * 0.66) rem(0 * 0.66) rgba(0, 0, 0, 0.3);
// }

// .ui-recap .text {
//   padding: rem(20 * 0.66);
// }

// .ui-substep-header-grid {
//   display: grid;
//   grid-template-columns: repeat(8, 1fr);
//   grid-gap: 0.5em;
// }

// .ui-substep-header-grid label:nth-child(2) {
//   grid-column: 6;
//   grid-row: 1;
//   text-align: center;
//   }
// .ui-substep-header-grid div:nth-child(3) {
// grid-column: 7;
// grid-row: 1;
// text-align: center;
// }
// .ui-substep-header-grid div:nth-child(4) {
//   grid-column: 8;
//   grid-row: 1;
//   text-align: center;
//   }
//   .ui-substep-header-grid input[type=button] {
//     width: 90% !important;
//   }
