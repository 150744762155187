@import "../variables-etc.scss";

.sidebarlistentry {
  &_link {
    display: block;
    height: rem(100 * 0.66);
    line-height: rem(100 * 0.66);
    position: relative;
    border-bottom: rem(1 * 0.66) solid #d6d6d6;
    cursor: pointer;

    img {
      margin-right: 0.5em;
      margin-left: 1em;
      width: 24px;
    }
  }

  &_bordered {
    border-left: 2px solid $pink;
    border-bottom: rem(1 * 0.66) solid #d6d6d6;
    .sidebarlistentry_link {
      border-bottom: 0;
    }
  }

  &_arrow,
  &_arrow_active {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: auto !important;
  }
  &_arrow_active {
    transform: rotate(90deg);
    width: 11px !important;
    height: 14px;
  }
}
