@import "../variables-etc.scss";

.container {
  height: calc(100% - 53px);

  &_row {
    height: 100%;
  }
}

.left {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  min-height: rem(1050 * 0.66);
  &_logo {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
    width: 350px;
  }
  &_pic02 {
    height: rem(301 * 0.66);
    width: rem(573 * 0.66);
    background: url("../../assets/language_02.png");
    background-size: contain;
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -70%);
    ul {
      list-style: none;
      padding: rem(60 * 0.66);
      li {
        color: white;
        font-weight: 300;
        font-size: rem(30 * 0.66);
        span {
          width: rem(27 * 0.66);
          height: rem(19 * 0.66);
          background: url("../../assets/language-check@2x.png");
          display: inline-block;
          margin-right: rem(20 * 0.66);
          background-size: cover;
        }
      }
    }
  }
}

.right {
  &_title {
    max-width: rem(600 * 0.66);
    margin: rem(150 * 0.66) auto rem(50 * 0.66);
  }
  &_img {
    width: 100%;
  }
  &_form {
    margin-top: rem(70 * 0.66);
  }
  &_label {
    font-size: 16px;
    font-weight: 600;
    color: $blue;
    display: block;
  }
  &_form {
    width: rem(200 * 0.66);
  }
  h3 {
    font-family: "Nothing You Could Do", cursive;
    font-weight: 400;
  }

  &_feder {
    width: 95%;
    position: absolute;
    bottom: 10px;
  }
}

@media screen and (max-width: 768px) {
  .right {
    &_form {
      margin: rem(70 * 0.66) auto auto auto;
      text-align: center;
    }

    &_feder {
      position: inherit;
    }
  }

  // .left {
  //   &_pic02 {
  //     background-size: contain;
  //   }
  // }
}
