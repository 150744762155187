@import "../variables-etc.scss";

.sidebarmenu {
  background-color: $gray;
  overflow-y: auto;
  height: 70%;
  h1 {
    font-size: 18px;
    font-weight: 500;
    color: $blue;
    padding-left: 1.5em;
    position: relative;
    margin-top: rem(30 * 0.66);
    border-bottom: 1px solid $blue;
    padding-bottom: 1em;
    width: 90%;
    margin-bottom: 0;
  }
  &_separator {
    width: 90%;
    //border-bottom: rem(1 * 0.66) solid $blue;
    margin: rem(20 * 0.66) 0 rem(10 * 0.66) 0;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: $blue;
    border-radius: 15px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 15px;
    background: darkgrey;
  }
}

.sidebar_list {
  list-style: none;
  width: 90%;
  font-size: rem(22 * 0.66);
  color: $blue;
  font-weight: 700;
  padding: 0;
  ul {
    list-style: none;
    padding-left: 1.8em;

    &:not(:first-child) {
      border-left: dotted 2px darkgrey;

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        transform: rotate(90deg);
        background: grey;
      }
    }

    li {
      padding: 0.3em;
    }
  }

  ul:first-child {
    padding-left: 2em;
    > li:before {
      content: url(../../assets/sidebar-arrow-small@2x.png);
      display: inline-block;
      float: left;
      margin-right: 10px;
    }
  }
}

@include lessThan(md) {
  .sidebarmenu {
    width: 100%;

    h1 {
      width: 100%;
    }
  }

  .sidebar_list {
    width: 100%;
  }
}
