@import "../variables-etc.scss";

.project-list-entry > * {
  display: inline-block;
}

.project-list-entry {
  display: flex;
  align-items: flex-start;

  > *:first-child {
    margin-right: auto;
  }

  &:hover {
    .project-svg {
      svg:not(.logo-inversed) {
        background-color: white;

        path,
        rect {
          fill: $cyan;
        }
      }
    }
  }

  .project-svg {
    svg:not(.logo-inversed) {
      background-color: $cyan;
      border-radius: 25px;
      margin: auto 5px;
      padding: 5px;
      height: 30px;
      width: 30px;

      path,
      rect {
        fill: white;
      }
    }

    &:hover {
      .project-svg {
        svg.logo-inversed {
          background-color: $cyan;

          path,
          rect {
            fill: white;
          }
        }
      }
    }

    svg.logo-inversed {
      background-color: white;
      border-radius: 25px;
      margin: auto 5px;
      height: 30px;
      width: 30px;

      path,
      rect {
        fill: $cyan;
      }
    }

    img {
      margin-right: 0.5em;
      width: 34px;
      height: 30px;
    }
  }

  &:hover {
    .template-svg {
      svg {
        background-color: white;

        path,
        text {
          fill: #e6786b;
        }
      }
    }
  }

  .template-svg {
    svg {
      background-color: #e6786b;
      border-radius: 25px;
      margin: auto 5px;
      padding: 3px;
      height: 30px;
      width: 30px;

      path,
      rect {
        fill: white;
      }
    }

    img {
      margin-right: 0.5em;
      width: 34px;
      height: 30px;
    }
  }

  svg:not(.project-svg > svg) {
    height: 20px;
    width: 20px;
    fill: $blue;

    &:hover {
      fill: $cyan;
    }
  }

  .project-tooltip {
    margin-left: 10px;
  }
}

.blue_label {
  color: $blue;
  margin-right: 5px;
}

.project-save {
  form {
    display: flex;
    flex-direction: column;
    padding: 10px;

    label {
      font-size: 16px;
      font-weight: 600;
      color: #313d57;
    }

    div:not(.return-submit-button) {
      display: flex;
      flex-direction: column;
      margin-top: 5%;
    }
  }
}

@media (max-width: 768px) {
  .project-list-entry {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  > *:last-child {
    display: flex;
    flex-direction: column;
  }
}
