@import "../variables-etc.scss";

.subprojectcontent {
  h1 {
    color: $blue;
    font-family: "Hind", sans-serif;
    font-size: 32px;
    font-weight: 700;
    margin-top: 1em;

    &:after {
      content: "";
      width: 46px;
      height: 2px;
      background: $blue;
      display: block;
      margin-top: 0.3em;
    }
  }

  .steps {
    display: flex;
    margin: 2em 0;
    justify-content: space-between;
    font-family: "Hind", sans-serif;
    font-size: 18px;

    &_detail,
    &_detail_active {
      width: 100%;
      position: relative;

      &:after {
        content: "";
        display: block;
        background: #f7f5f5;
        height: 13px;
        position: absolute;
        width: 100%;
        bottom: 7px;
        z-index: 2;
        left: 4px;
      }

      &:first-child:after {
      }

      &:last-child:after {
        display: none;
      }

      div {
        color: $blue;
        margin-bottom: 0;
      }

      p {
        color: $black;
        font-size: 13px;
        font-weight: bold;
        padding-bottom: 7px;
      }

      span {
        display: block;
        z-index: 3;
        position: absolute;
        width: 11px;
        height: 11px;
        background: $pink;
        border-radius: 50%;
        bottom: 8px;
      }
    }

    &_detail_active {
      span {
        width: 27px;
        height: 27px;
        bottom: 1px;
      }
    }

    &_detail_active ~ .steps_detail {
      &:after {
        background: #f7f5f5;
      }
    }

    &_detail {
      &:after {
        background: #e6786b;
      }
    }
  }
}

@include lessThan(md) {
  .subprojectcontent {
    .steps {
      display: none;
    }
  }
}
