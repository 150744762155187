@import "../variables-etc.scss";

.admin-page-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  > *:last-child {
    flex-grow: 1;
    flex-basis: 100%;
  }
}


.admin-page-main {
  display: flex;
  padding: 20px;
  flex-direction: column;
  overflow: hidden;

  > *:last-child {
    flex-grow: 1;
    flex-basis: 100%;
  }
}

.admin-page-results {
  width: 100%;
  height: 100%;
}

.projects_list {
  list-style-type: none;
  &:empty {
    display: none;
  }

  li {
    margin-bottom: 10px;
    background-color: $gray;
    border: none;

    &:hover {
      box-shadow: 0px 3px 5px grey;
    }
  }
}

.project-list-entry > * {
  display: inline-block;
}

.project-list-entry {
  display: flex;
  align-items: flex-start;

  > *:first-child {
    margin-right: auto;
  }

  &:hover {
    .project-svg {
      svg:not(.logo-inversed) {
        background-color: white;

        path,
        rect {
          fill: $cyan;
        }
      }
    }
  }

  .project-svg {
    svg:not(.logo-inversed) {
      background-color: $cyan;
      border-radius: 25px;
      margin: auto 5px;
      padding: 5px;
      height: 30px;
      width: 30px;

      path,
      rect {
        fill: white;
      }
    }

    &:hover {
      .project-svg {
        svg.logo-inversed {
          background-color: $cyan;

          path,
          rect {
            fill: white;
          }
        }
      }
    }

    svg.logo-inversed {
      background-color: white;
      border-radius: 25px;
      margin: auto 5px;
      height: 30px;
      width: 30px;

      path,
      rect {
        fill: $cyan;
      }
    }

    img {
      margin-right: 0.5em;
      width: 34px;
      height: 30px;
    }
  }

  svg:not(.project-svg > svg) {
    height: 20px;
    width: 20px;
    fill: $blue;

    &:hover {
      fill: $cyan;
    }
  }
}
