@import "../variables-etc.scss";
@import "../abstract/utils.scss";

.container {
  height: calc(100% - 53px);

  &_row {
    height: 100%;
  }
}

.ui-error {
  color: $errorColor;
}

.left {
  position: relative;
  padding: 0 !important;
  &_carousel {
    height: 100%;

    &_item {
      height: 100%;
    }
  }

  &_arrowleft {
    width: rem(47 * 0.66);
    height: rem(62 * 0.66);
    background: url("../../assets/login_arrow@2x.png");
    position: absolute;
    top: 50%;
    left: rem(30 * 0.66);
    background-size: cover;
    cursor: pointer;
  }
  &_arrowright {
    width: rem(47 * 0.66);
    height: rem(62 * 0.66);
    background: url("../../assets/login_arrow@2x.png");
    position: absolute;
    top: 50%;
    right: rem(30 * 0.66);
    transform: rotate(-180deg);
    background-size: cover;
    cursor: pointer;
  }
  &_picture {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: calc(100vh - 53px);
  }
  &_logo {
    position: absolute;
    top: 25%;
    left: 20%;
    transform: translate(-20%, -30%);
    width: 350px;
  }
  &_dots {
    position: absolute;
    bottom: rem(30 * 0.66);
    left: 50%;
    transform: translateX(-50%);
    span {
      width: rem(11 * 0.66);
      height: rem(11 * 0.66);
      vertical-align: middle;
      background: rgba(255, 255, 255, 0.53);
      border-radius: 50%;
      display: inline-block;
      margin: 0 rem(20 * 0.66);
      cursor: pointer;
    }
    &_active {
      width: rem(19 * 0.66) !important;
      height: rem(19 * 0.66) !important;
      background: white !important;
    }
  }
}

.right {
  padding: rem(100 * 0.66) rem(50 * 0.66);
  &_start {
    margin-bottom: rem(100 * 0.66);
    &_title {
      font-size: rem(50 * 0.66);
      text-align: left;
      color: $blue;
      font-family: "Nothing You Could Do", cursive;
    }
    &_text {
      font-family: "Hind", sans-serif;
      color: $blue;
      font-size: rem(25 * 0.66);
      font-weight: 300;
    }
  }
  &_account {
    &_line {
      position: absolute;
      right: 0;
      top: 0;
      height: 234px;

      // @include lessThan(md) {
      //   top: 50%;
      //   height: 120%;
      //   left: 50%;
      // }
    }
    &_left {
      position: relative;
      padding-right: rem(40 * 0.66);

      // @include lessThan(md) {
      //   margin-bottom: 15%;
      // }
    }
    &_label {
      font-size: 16px;
      font-weight: 600;
      color: $blue;
      display: block;
    }
    &_mdp {
      display: block;
      font-size: 14px;
      text-decoration: underline;
      margin-top: rem(20 * 0.66);
    }
    &_right {
      padding-left: rem(40 * 0.66);
    }
  }
  &_text {
    font-size: 16px;
    font-weight: 300;
    margin-top: rem(30 * 0.66);
  }
}

.header-login {
  div:last-child {
    display: flex;
  }

  // div {
  //   > svg:not(:first-child) {
  //     display: none;
  //   }
  // }

  // .main-navbar_left {
  //   display: flex;
  // }
}

@media (max-width: 992px) {
  .left {
    min-height: 400px;
  }
  .right {
    &_account {
      &_line {
        transform: rotate(90deg);
        top: 33%;
        height: 160%;
        left: 50%;
      }

      &_left {
        margin-bottom: 15%;
      }
    }
  }
}

@media (max-width: 768px) {
  .header-login {
    div {
      > span {
        font-size: 0.8em;
      }

      div:last-child:not(.main-navbar_call-container) {
        display: initial;
      }

      // &:first-child {
      //   > span {
      //     display: none;
      //   }
      // }
    }

    > div:not(.main-navbar_call-container) > svg {
      cursor: pointer;
    }

    // > span {
    //   display: none;
    // }
    &:not(:first-child):not(.main-navbar_call-container) > svg {
      display: inline-flex;
      width: 40px;
      height: 40px;
      margin: auto;
      position: absolute;
      top: -10%;
      right: 0;
    }
  }

  .login-container {
    > div {
      z-index: 2;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        background: rgba($color: $blue, $alpha: 0.8);
        z-index: 1;
        height: 100%;
        width: 100%;
      }
    }
  }
}

@media (max-width: 576px) {
  .right {
    text-align: center;

    &_start {
      &_title {
        text-align: center;

        &::after {
          left: 13%;
        }
      }

      button {
        &:last-child {
          margin-top: 2%;
        }
      }
    }

    &_account {
      &_right {
        &_btn-toolbar > div {
          margin: auto;
        }
      }
    }
  }
}

.ui-register {
  form {
    display: flex;
    flex-direction: column;

    // label {
    //   font-size: 16px;
    //   font-weight: 600;
    //   color: #313d57;
    // }

    // input {
    //   padding: 0.375rem 0.75rem;
    // }

    div:not(.return-submit-button):not(:nth-child(5)) {
      display: flex;
      flex-direction: column;
      margin-bottom: 5%;
    }
  }
}

.return-submit-button {
  margin: 5% auto;
}

// .main-navbar_call-container {
//   display: flex;

//   svg {
//     display: none;
//   }
// }
