@import "../variables-etc.scss";
.nsstepview {
  h3 {
    text-align: center;
  }
  &_icoquestion {
    display: inline-block !important;
    margin-left: 1em;
  }
}
