@import "../variables-etc.scss";
.bottombar {
  &_right {
    align-items: center;
    display: flex;
    justify-content: end;
    &_delete {
      margin-right: 1em;
      svg {
        margin-right: 0.5em;
      }
      cursor: pointer;
    }

    a,
    button {
      height: rem(84 * 0.66);
      font-weight: bold;
      padding: 0 2.5em 0 2em !important;
      display: inline-flex;
      align-items: center;
      border-radius: 5px !important;
      text-transform: uppercase;
      position: relative !important;
      margin-left: 1.5em;

      &:after {
        content: "";
        background: url("../../assets/footer-btn-arrow-blue.png");
        width: rem(14 * 0.66);
        height: rem(22 * 0.66);
        display: inline-block;
        position: absolute;
        left: rem(20 * 0.66);
        right: auto;
        top: 50%;
        transform: translateY(-50%);
        background-size: cover;
      }
      &:last-child {
        &:after {
          background: url("../../assets/footer-btn-arrow.png");
          right: rem(20 * 0.66);
          left: auto;
          background-size: cover;
        }
      }
    }
  }

  /*&_left {
    display: flex;
    justify-content: space-between;
    margin: auto;
  }*/
}

@media (max-width: 992px) {
  .bottombar {
    &_right {
      &_delete {
        span {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .bottombar {
    &_right {
      width: 100%;
      span {
        order: 1;
        margin: auto;

        * {
          margin: auto;
        }
      }

      a {
        margin-left: 0;
      }

      button {
        order: 2;
        margin-left: 0;
      }
    }

    &_left {
      display: none;
    }
  }

  .main-footer_left {
    display: none;
  }
}
