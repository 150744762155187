@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

@import "./abstract/utils.scss";
@import "./abstract/project-mixins.scss";
@import "./bootstrap/bootstrap-custom-variables.scss";

$color-btn-primary: #3f5179;
$errorColor: rgb(134, 33, 33);
