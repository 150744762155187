// rem\(([0-9]*) \* 0\.66\)

html {
  box-sizing: border-box;
}
* {
  box-sizing: inherit;
}

html,
body,
#root {
  height: 100%;
}

body {
  font-family: $font-family-sans-serif;
}

html {
  box-sizing: border-box;
}

/* * {
  background-color: #333  !important;
  color: #ccc !important;
}  */

* {
  box-sizing: inherit;
}

html,
body,
#root {
  // overflow: hidden;
  width: 100%;
  height: 100%;
  font-family: "Hind", sans-serif;
}

.ui {
  display: grid;
  height: 100%;
  overflow: hidden;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr auto;

  > *:first-child {
    grid-column: span 2;
  }

  > *:nth-child(4) {
    grid-column: span 2;
  }
}

.main-navbar {
  font-family: "Hind", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: white;
  background: $pink;
  height: rem(80 * 0.66);
  line-height: rem(40 * 0.66);
  padding: rem(20 * 0.66);
  width: 100%;
  left: 0;
  top: 0;
  z-index: 3;

  &_call-grp {
    // display: flex;
    // margin: 0 auto;

    * {
      margin: auto;
    }
  }

  &_question {
    text-transform: uppercase;
  }

  &_logo,
  &_logo-question {
    margin-right: rem(15 * 0.66);
  }
  &_call {
    font-size: 18px;
    font-weight: 500;
    color: $blue;
    padding: 0 rem(5 * 0.66) 0 rem(15 * 0.66);
  }
  &_number {
    font-size: 18px;
    margin: auto;
  }
  &_links {
    font-size: 18px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &_exit {
      width: 20px !important;
    }
    div {
      margin: auto;
    }
    img {
      margin: auto 15px auto auto;
      width: 14.44px;
      margin-top: -3px;
    }
    button,
    a {
      color: white;
      font-size: inherit;
      padding: 0;
      &:hover {
        color: white;
        text-decoration: none;
      }
    }
  }
  &_save-label {
    margin-left: 5px;
    color: white !important;
    text-decoration: none !important;
  }
}

h2 {
  font-size: rem(45 * 0.66);
  text-align: left;
  color: $blue;
  font-weight: bold;
  position: relative;
  padding-bottom: rem(20 * 0.66);
  &:after {
    width: rem(69 * 0.66);
    border: rem(1 * 0.66) solid $blue;
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
  }
}
h3.project_new {
  font-size: 22;
  font-weight: 700;
  margin-bottom: 2em;
}

.ico-question {
  width: rem(40 * 0.66);
  height: rem(40 * 0.66);
  border: rem(3 * 0.66) solid $pink;
  background: #f8a69c;
  display: block;
  border-radius: 50%;
  color: white;
  font-size: rem(26 * 0.66);
  font-weight: 600;
  text-align: center;
  line-height: rem(35 * 0.66);
  cursor: pointer;
  &.ico-xs {
    width: rem(28 * 0.66);
    height: rem(28 * 0.66);
    font-size: rem(17 * 0.66);
    line-height: rem(22 * 0.66);
  }
}

.main-sidebar {
  z-index: 3;
  width: rem(408 * 0.66);
  height: 100%;
  background: $gray;
  top: rem(80 * 0.66);
  overflow: auto;
  left: 0;
  &_logo {
    width: 100%;
    text-align: center;
    margin: 3em 0;
    img {
      width: 190px;
    }
  }
}

.popup-sidebar {
  position: absolute;
  top: 52px;
  left: 0;
  width: 100%;
  z-index: 1;
  height: 100vh;
  background-color: rgba(49, 61, 87, 0.8);
  overflow: auto;
  &_logo {
    background-color: $gray;
    width: 100%;
    text-align: center;
    img {
      width: 35%;
    }
  }
}

.link-www {
  color: white !important;
  &:hover {
    text-decoration: none !important;
  }
}

.main-body {
  overflow: auto;
  .main-wrapper {
    padding: 3em;
  }
}

.main-footer {
  height: rem(136 * 0.66);
  bottom: 0;
  left: 0;
  background: #fff;
  width: 100%;
  border: rem(1 * 0.66) solid #c0b3a9;
  z-index: 3;
  &_left {
    width: rem(408 * 0.66);
    height: 100%;
    background: $blue;
    color: white;
    font-size: rem(30 * 0.66);
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    float: left;
    background-position: bottom;
    background-size: cover;
    &_overlay {
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }
    .label-0 {
      margin: auto;
      position: relative;
      top: 30px;
      width: 240px;
      text-overflow: ellipsis;
      line-break: unset;
      height: 20px;
      overflow: hidden;
      white-space: nowrap;
    }
    .label-1 {
      margin: auto;
      position: relative;
      top: 16px;
      width: 240px;
      text-overflow: ellipsis;
      line-break: unset;
      height: 20px;
      overflow: hidden;
      white-space: nowrap;
    }
    .label-2 {
      margin: auto;
      position: relative;
      top: 34px;
      width: 240px;
      text-overflow: ellipsis;
      line-break: unset;
      height: 20px;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  &_right {
    .bottom-label {
      position: relative;
      top: 10px;
    }
    .color-thumbnail {
      height: 55px;
      margin-left: 30px;
      position: relative;
      bottom: 7px;
    }
  }
}

.main-footer-container {
  padding: 1.1rem !important;
}

.substeps-list__entry--selected {
  background: rgba(230, 120, 107, 0.18);
  padding: 0.5em;
}

/* Ligne avec checkbox + tooltip + checkbox */
.linecheckbox {
  background: #f7f5f5;
  color: #2c2828;
  font-size: 22px;
  padding: 1em;
  line-height: 1em;
  margin-bottom: 10px;
  display: flex;
  &_right {
    margin: auto 0 auto auto;
    display: inline-flex;
    .ico-question {
      margin-right: 1em;
      display: inline-block;
      margin-left: 2em;
    }
  }
  &_left {
    float: left;
  }
}
.linecheckbox_container_checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.linecheckbox_container {
  display: block;
  float: right;
  position: relative;
  padding-left: 35px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &_clear {
    clear: both;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  input:checked ~ .linecheckbox_container_checkmark:after {
    display: block;
  }

  &_checkmark {
    position: absolute;
    top: -5px;
    left: 0;
    height: 32px;
    width: 32px;
    border: 1px solid #313d57;
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }
  & input[type="radio"] ~ .linecheckbox_container_checkmark {
    border-radius: 50%;
    top: -3px;
  }
  .linecheckbox_container_checkmark:after {
    left: 9px;
    top: 5px;
    width: 11px;
    height: 16px;
    border: solid #313d57;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  & input[type="radio"] ~ .linecheckbox_container_checkmark:after {
    width: 80%;
    height: 80%;
    border: none;
    background-color: #313d57;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:hover input ~ .linecheckbox_container_checkmark {
    background-color: #ccc;
  }
}

a,
.btn-link > span:not(.main-navbar_save-label) {
  text-decoration: none;
  color: $blue !important;
}

@include lessThan(md) {
  .main-sidebar {
    position: absolute;
    width: 100%;
    display: none;

    &_logo {
      display: none;
    }
  }

  .main-navbar {
    &_question {
      display: none;
    }

    &_call {
      display: none;
    }

    &_save-label {
      display: none;
    }
  }
}

@include lessThan(sm) {
  .main-body {
    .main-wrapper {
      padding: 1em;
    }
  }
}

@media (max-width: 1400px) {
  .main-navbar {
    &_number {
      margin-left: 1em;
    }
  }
}

@media (max-width: 1200px) {
  .main-navbar {
    &_links {
      img {
        width: 20px;
        margin: auto 0.1em;
      }

      &_user-pseudo {
        display: none;
      }

      .btn {
        span {
          display: none;
        }
      }
    }

    &_call-grp {
      svg {
        width: 30px;
        height: 30px;
      }
    }

    &_acces-site {
      display: none;
    }
  }
}

@media (max-width: 576px) {
  .main-navbar {
    &_number {
      display: none;
    }

    &_links {
      div {
        margin: auto 10px;
      }
    }

    /*img {
      width: 30px !important;
      margin: 0;
    }*/

    &_call-grp {
      svg {
        margin-right: 0;
      }
    }
  }

  .main-footer {
    height: rem(100 * 0.66);
  }

  .main-footer-container {
    padding: 0.8rem !important;
  }

  .btn.btn-bar {
    font-size: 12px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    height: 45px;
    line-height: 0.9rem;
  }

  .btn.btn-bar-left {
    font-size: 12px !important;
    padding-left: 30px !important;
    padding-right: 15px !important;
    height: 45px;
    line-height: 0.9rem;
  }
  .btn.btn-bar-right {
    font-size: 12px !important;
    padding-left: 15px !important;
    padding-right: 30px !important;
    height: 45px;
    line-height: 0.9rem;
  }
}

.alert {
  padding: 0.25rem 0.25rem !important;
  margin-bottom: 0.5rem !important;
}

.btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  color: #fff !important;
  background-color: #1f2636 !important;
  border-color: #1a202e !important;
}

.tarteaucitronAlertBigBottom{
  background-color: #e6786b !important;
}
