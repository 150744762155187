@import "../variables-etc.scss";
.bottombar {
  &_right {
    align-items: center;

    a,
    button {
      height: rem(84 * 0.66);
      margin: 10px;
      font-weight: bold;
      padding: 0 2.5em 0 2em !important;
      border-radius: 5px !important;
      text-transform: uppercase;
      position: relative !important;
      margin-left: 1.5em;

      &:after {
        content: "";
        background: url("../../assets/footer-btn-arrow.png");
        width: rem(14 * 0.66);
        height: rem(22 * 0.66);
        display: inline-block;
        position: absolute;
        right: rem(20 * 0.66);
        top: 50%;
        transform: translateY(-50%);
        background-size: cover;
      }
    }
  }
}
