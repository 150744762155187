@import "../variables-etc.scss";

.header__call-popup {
  display: none;
}

.blue-background {
  .header-login {
    display: flex;

    .main-navbar_call-grp {
      flex-grow: 2;
      padding-left: 10%;
    }
  }

  .right {
    flex-grow: 2;
  }
}

.menu-responsive {
  display: none;
}

.btn-header{
  background-color: $pink;
  border: none;
}

@include lessThan(md) {
  .menu-responsive {
    display: block;
  }

  /*.logout {
    display: none;
  }*/
}

@include lessThan(sm) {
  .blue-background {
    position: relative;

    &::after {
      display: none;

      content: "";
      position: absolute;
      background-color: rgba($blue, 0.7);
      left: 0;
      height: 225vh;
      width: 100vw;
      z-index: -1;
    }

    .main-navbar_call-grp.main-navbar_call-grp {
      padding-left: inherit;
      text-align: right;
      padding-right: 1%;
    }
  }

  .header__call-popup {
    position: absolute;
    top: 52px;
    left: 0;
    width: 100%;
    background-color: rgba($pink, 0.9);
    z-index: 1;
    display: flex;
    // display: none;
    padding: 5%;

    svg {
      margin: auto 5% !important;
    }

    .header__call-popup-content {
      // display: none;
      display: flex;
      flex-direction: column;
      width: 100%;

      span {
        display: block;
      }

      > div {
        width: 100%;
        .header__call-popup-content-text {
          flex-grow: 1;
          > span {
            color: $blue;
            margin-right: 3%;
          }
        }

        span {
          flex-grow: 20;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .header__logo {
    span {
      display: none;
    }
  }
}
