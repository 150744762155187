@import "../variables-etc.scss";

.ui-item-table-title {
  margin-bottom: 30px;
}

.ui-item-grid {
  max-width: rem(1000 * 0.66);
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 4fr 1fr;
  grid-gap: 2em;
  color: #222;
  border-bottom: rem(1 * 0.66) solid #ddd;
  padding: 1em 0;
}

.ui-item-grid label {
  font-weight: 800;
}
.ui-item-grid label:first-child {
  text-align: right;
}

.ui-item-grid-header {
  text-decoration: underline;
  border-bottom: unset;
}

.ui-item-table-tr td {
  vertical-align: middle !important;
}

.ui-item-picture {
  width: 125px;
}

.ui-item-alert {
  color: $red;
}

.ui-item-table-td a {
  color: $cyan;
  text-decoration: underline;
}

.bottombar_right {
  align-items: center;
  display: flex;
  justify-content: end;

  a,
  button {
    height: rem(84 * 0.66);
    font-weight: bold;
    // padding: 0 2.5em 0 2em !important;
    display: flex !important;
    align-items: center;
    border-radius: 5px !important;
    text-transform: uppercase;
    position: relative !important;
    margin-left: 1.5em;
  }
}

@include lessThan(lg) {
  .ui-item-table thead {
    display: none;
  }

  .ui-item-table tr {
    display: block;
    margin-bottom: 40px;
  }

  .ui-item-table td:not(:first-child):not(:last-child) {
    display: block;
    text-align: right;
  }

  .ui-item-table td:first-child,
  .ui-item-table td:last-child {
    text-align: center;
    display: block;
  }

  .ui-item-table td:before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }

  .bottombar_right {
    a,
    button {
      font-size: 0.8em !important;
    }
  }
}

@include lessThan(md) {
  .bottombar {
    &_right {
      width: 100%;

      a {
        margin-left: 0;
      }
    }
  }

  .main-footer_left {
    display: none;
  }
}

@include lessThan(sm) {
  .bottombar_right {
    a,
    button {
      font-size: 0.6em !important;
    }
  }
}

@media (max-width: rem(600 * 0.66)) {
  .ui-item-grid {
    grid-template-columns: 1fr;
    grid-gap: 0.5em;
  }

  .ui-item-grid label:first-child {
    text-align: left;
    margin: 0;
  }

  .ui-reference-col {
    margin-top: -rem(10 * 0.66);
    font-style: italic;
    order: 1;
  }

  .ui-quantity-col {
    order: 2;
  }

  .ui-item-grid-header {
    display: none;
  }
}

body {
  font-family: "Open Sans", sans-serif;
}

h2 {
  text-align: center;
}

.ui-items-wrapper {
  padding: 0 1em;
}
