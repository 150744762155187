// TODO
h3 {
  color: $blue;
  font-size: 22px;
  font-weight: 600;
}
.container-fluid {
  padding: 0;
}

.btn.btn-primary {
  color: white;
  font-weight: bold;
  font-family: "Hind", sans-serif;
  font-size: 14px;
  padding: rem(10 * 0.66) rem(25 * 0.66);
  background: $color-btn-primary;
  border-color: $color-btn-primary;
  text-transform: uppercase;
  border-radius: rem(2 * 0.66);
}
.btn.btn-secondary {
  font-weight: bold;
  font-family: "Hind", sans-serif;
  font-size: 14px;
  padding: rem(10 * 0.66) rem(25 * 0.66);
  background: #ffffff;
  color: $blue;
  text-transform: uppercase;
  border-radius: rem(2 * 0.66);
}

.btn-link {
  color: white !important;
  text-decoration: none !important;
}

.form-control {
  border-radius: rem(2 * 0.66);
  height: rem(50 * 0.66);
  line-height: rem(50 * 0.66);
  color: #939393;
  font-size: 14px;
}
.row {
  margin: 0 !important;
}

.form-range {
  &::-webkit-slider-thumb {
    background-color: white;
    border: 5px solid $pink;
    width: 22px;
    height: 22px;
    // margin-top: 1px;
  }

  &::-webkit-slider-runnable-track {
    height: 15px;
    background-color: $gray;
  }

  &::-moz-range-thumb {
    background-color: $pink;
  }

  &::-ms-thumb {
    background-color: $pink;
  }
}

.input-group > .form-control {
  height: auto;
}

.modal-content {
  width: 80%;
  margin: auto;

  .modal-header {
    position: relative;
    border-bottom: none;

    .close {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  .modal-footer {
    border-top: none;
  }
}

@media (max-width: 768px) {
  .modal-content {
    width: 100% !important;
    margin: 0 !important;
  }
}

.carousel-inner {
  height: 100%;
}

.carousel-control-prev {
  width: rem(47 * 0.66);
  height: rem(62 * 0.66);
  background: url("../../assets/login_arrow@2x.png");
  position: absolute;
  top: 50%;
  left: rem(30 * 0.66);
  background-size: cover;
  cursor: pointer;
}

.carousel-control-prev-icon {
  background: none;
}

.carousel-control-next {
  width: rem(47 * 0.66);
  height: rem(62 * 0.66);
  background: url("../../assets/login_arrow@2x.png");
  position: absolute;
  top: 50%;
  right: rem(30 * 0.66);
  transform: rotate(-180deg);
  background-size: cover;
  cursor: pointer;
}

.carousel-control-next-icon {
  background: none;
}

.carousel-indicators {
  li {
    width: rem(11 * 0.66);
    height: rem(11 * 0.66);
    vertical-align: middle;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 50%;
    display: inline-block;
    margin: 0 rem(20 * 0.66);
    cursor: pointer;
    border-top: none;
    border-bottom: none;
  }

  li.active {
    width: rem(19 * 0.66) !important;
    height: rem(19 * 0.66) !important;
    background: white !important;
  }
}
