@import "../variables-etc.scss";

.ui-color-body-step {
  padding: rem(40 * 0.66) 0;
}

.ui-color-step {
  width: 75%;
  padding-right: rem(15 * 0.66);
  padding-left: rem(15 * 0.66);
  margin-right: auto;
  margin-left: auto;
}

.ui-color-step-grid {
  display: grid;
  grid-template-rows: repeat(auto-fill, minmax(rem(280 * 0.66), 1fr));
  grid-gap: rem(20 * 0.66);
  align-items: stretch;
}

.ui-color-step-grid > .nuancier {
  border: rem(1 * 0.66) solid #ccc;
  box-shadow: rem(2 * 0.66) rem(2 * 0.66) rem(6 * 0.66) rem(0 * 0.66)
    rgba(0, 0, 0, 0.3);
}

.ui-color-step-grid .text {
  padding: rem(20 * 0.66);
}

.ui-color-board {
  display: grid;
  grid-auto-rows: auto;
  // grid-template-columns: repeat(6, auto);
  //     grid-template-columns: repeat(8, rem(150 * 0.66));
}

@include lessThan(xl) {
  .ui-color-step {
    width: 100%;
  }

  .ui-color-board {
    /*display: flex;
    flex-direction: column;
    flex-flow: wrap;*/

    > div {
      width: 100%;
    }
  }
}

@include lessThan(lg) {
  .ui-color-board {
    > div {
      width: 100%;
    }
  }
}

// for some css stylings
body {
  display: flex;
  justify-content: center;
}

.ui-color-box-no-color {
  position: relative;
  padding-top: 100%;

  .ui-color-box {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    border: 5px solid $red;

    > label {
      text-align: center;
      font-size: medium;
      display: flex;
      align-items: center;
      padding: 10px;
    }
  }
}

.ui-color-box {
  background: #fff;
  border: 5px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aaa;
  height: 100%;
  width: 100%;
  position: relative;
  // padding: 10px;
  cursor: pointer;

  .search-ico {
    display: none;
  }

  &:hover {
    transform: scale(1.2);
    z-index: 2;
    border: 5px solid white;

    > input:checked + label {
      // outline: solid 5px red;
      z-index: 1;
      // transform: scale(1.055);
    }

    .search-ico {
      display: block;
      z-index: 2;
      width: 25px;
      height: 25px;
      position: absolute;
      top: 15px;
      left: 15px;
      background: grey;
      padding: 5px;
      border-radius: 15px;
      cursor: pointer;
    }
  }

  > img {
    width: 100%;
    height: 100%;
    // border: solid thin #ccc;
    // padding: 3px;
  }

  > label,
  > input {
    overflow: hidden;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 0;
  }

  > input {
    opacity: 0;
  }

  &[data-checked="true"],
  > input:checked + label {
    border: solid 4px $pink;
    z-index: 1;
  }
}

.close-btn.close-btn {
  background: none;
  border: none;
  margin: 3% 5% auto auto;
}

.modal-color_label {
  font-weight: 600;
  color: $blue;
}

.modal-color-picture {
  width: 100%;
}
