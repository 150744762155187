@import "../variables-etc.scss";

// .ui-login-wrapper {
//   width: 25%;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
// }

.ui-error {
  color: $errorColor;
  margin-top: 0 !important;
}

.ui-register {
  // border: $pink 1px solid;
  form {
    display: flex;
    flex-direction: column;
    padding: 10px;

    label {
      font-size: 16px;
      font-weight: 600;
      color: #313d57;
    }

    div:not(.return-submit-button) {
      display: flex;
      flex-direction: column;
      margin-top: 5%;
    }
  }

  h3 {
    text-align: center;
  }
}

.account_pwd {
  display: block;
  font-size: 14px;
  text-decoration: underline;
  margin-top: rem(20 * 0.66);
}

.return-submit-button {
  margin: 5% auto;
}
